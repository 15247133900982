import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Divider, 
  Paper, 
  Button, 
  Select, 
  MenuItem, 
  TextField, 
  Box,
  Snackbar,
  CircularProgress,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccountCircle,
  Payment,
  ExitToApp,
  Info,
  CloudUpload,
  Send,
  Folder,
  GetApp
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import ManageFolders from './ManageFolders';

const drawerWidth = 220; // Slightly reduced drawer width
const PRICE_PER_ASK = 10;

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#1DF2A1',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#000000',
      paper: '#121212',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#000000',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    paddingTop: theme.spacing(8),
    width: drawerWidth,
    backgroundColor: '#121212',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(8), // Prevent footer from covering content
    paddingLeft: theme.spacing(12), // Add more padding on the left
    backgroundColor: '#000000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoContainer: {
    width: '55%', // Increased from 40%
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  logo: {
    maxWidth: 320, 
    width: '100%', 
    height: 'auto', 
    transition: 'opacity 0.2s ease-in-out',
  },    
  paper: {
    padding: theme.spacing(2),
    backgroundColor: '#121212',
    color: '#ffffff',
    width: '80%',
    maxWidth: 'calc(100vw - 260px)', // Increased max width
    marginLeft: theme.spacing(6), // Add margin to the left
  },
  uploadButton: {
    margin: theme.spacing(2, 0),
  },
  askButton: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    marginBottom: theme.spacing(1),
    color: '#ffffff',
  },
  footer: {
    position: 'relative', // Change from 'fixed' to 'relative'
    left: 0,
    right: 0,
    padding: theme.spacing(1.5),
    backgroundColor: '#1A1A1A',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    marginTop: 'auto', // This helps push it to the bottom
  },  
  footerText: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginRight: theme.spacing(4), // Add some right margin
    '& span': {
      display: 'inline-flex',
      alignItems: 'center'
    }
  }
}));


function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [folders, setFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [model, setModel] = useState('Claude-3');
  const [language, setLanguage] = useState('English');
  const [writingStyle, setWritingStyle] = useState('Default (academic)');
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const [response, setResponse] = useState('');
  const [docxUrl, setDocxUrl] = useState('');
  const [credit, setCredit] = useState(0);
  const [error, setError] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [newBalance, setNewBalance] = useState(null);
  const [openManageFolders, setOpenManageFolders] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  
  useEffect(() => {
    fetchFolders();
    fetchUserInfo();

    // Check for payment success
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('payment_success') === 'true') {
      setPaymentSuccess(true);
      const newBalance = parseFloat(searchParams.get('new_balance'));
      setNewBalance(newBalance);
      setCredit(newBalance);
    }
  }, [location]);

  const fetchFolders = async () => {
    try {
      const response = await fetch('/api/processed-folders');
      const data = await response.json();
      // Filter to only show completed folders
      const completedFolders = data.filter(folder => folder.status === 'Completed');
      setFolders(completedFolders);
    } catch (error) {
      console.error('Error fetching folders:', error);
      setFolders([]); // Set empty array on error
    }
  };

  const fetchUserInfo = async () => {
    try {
      const response = await fetch('/api/user-info');
      const data = await response.json();
      setCredit(data.credit);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const handleManageFoldersOpen = () => {
    setOpenManageFolders(true);
  };

  const handleManageFoldersClose = () => {
    setOpenManageFolders(false);
    fetchFolders(); // Refresh the folder list after managing folders
  };

  const handleFolderUpload = async (event) => {
    const files = event.target.files;
    let statusCheckInterval = null;
    
    setIsUploading(true);
    setIsProcessing(true);  // Set at start of process
    setUploadError('');
    setUploadStatus('Starting upload...');
    
    try {
        const folderName = files[0].webkitRelativePath.split('/')[0];
        const fileList = Array.from(files).map(file => ({
            name: file.name,
            type: 'application/pdf',
            path: file.webkitRelativePath
        }));
        
        console.log('Getting pre-signed URLs...');
        const urlResponse = await fetch('/api/get-upload-url', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                folderName: folderName,
                files: fileList
            })
        });
 
        if (!urlResponse.ok) {
            throw new Error(`Failed to get upload URLs: ${urlResponse.status}`);
        }
 
        const urlData = await urlResponse.json();
        setUploadStatus(`Uploading files (0/${files.length})...`);

        let uploadedCount = 0;
        const uploadPromises = Array.from(files).map(async (file) => {
            const presignedUrl = urlData.urls[file.name];
            if (!presignedUrl) {
                throw new Error(`No pre-signed URL for ${file.name}`);
            }
 
            const uploadResponse = await fetch(presignedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': 'application/pdf',
                    'Content-Disposition': `attachment; filename="${file.name}"`
                }
            });
 
            if (!uploadResponse.ok) {
                throw new Error(`Failed to upload ${file.name}`);
            }

            uploadedCount++;
            setUploadStatus(`Uploading files (${uploadedCount}/${files.length})...`);
        });
 
        await Promise.all(uploadPromises);
        console.log('All files uploaded. Starting processing...');
        
        setUploadStatus('Files uploaded. Starting PDF processing...');
 
        console.log('Calling complete-upload API...');
        const completeResponse = await fetch('/api/complete-upload', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                folderName: urlData.folderName
            })
        });

        const completeData = await completeResponse.json();
        console.log('Complete-upload response:', completeData);
 
        if (!completeResponse.ok) {
            throw new Error('Failed to start processing');
        }

        // Set initial status from complete-upload response
        setUploadStatus(completeData.current_status || 'Starting processing...');

        // Define the status check function
        const checkStatus = async () => {
          try {
              const statusResponse = await fetch(`/api/folder-status/${urlData.folderName}`);
              const statusData = await statusResponse.json();

              if (statusData.status === 'Starting' || statusData.status === 'Completed') {
                  console.log('Checking completion status:', statusData.status);
              } else {
                  setUploadStatus(statusData.status);
              }

              if (statusData.status === 'Completed') {
                  if (statusCheckInterval) {
                      clearInterval(statusCheckInterval);
                  }
                  setUploadStatus('');
                  setUploadSuccess(true);
                  setIsProcessing(false);  // Release when complete
                  fetchFolders();
              }
          } catch (err) {
              console.error('Error checking status:', err);
          }
      };

        // Start polling
        statusCheckInterval = setInterval(checkStatus, 2000);
        console.log('Interval started:', statusCheckInterval);

    } catch (error) {
        setIsProcessing(false);  // Release on error
        console.error('Error in folder upload:', error);
        setUploadError(error.message || 'Failed to upload folder. Please try again.');
        setUploadStatus('');
        if (statusCheckInterval) {
            clearInterval(statusCheckInterval);
        }
    } finally {
        setIsUploading(false);
    }

    // Return cleanup function
    return () => {
        if (statusCheckInterval) {
            clearInterval(statusCheckInterval);
        }
    };
};

  const handleAsk = async () => {
    if (credit < PRICE_PER_ASK) {
      setError(`Insufficient balance. You need at least $${PRICE_PER_ASK} to ask a question.`);
      return;
    }
  
    setStatus('Processing...');
    setResponse('');
    setDocxUrl('');
  
    try {
      // Deduct balance first
      const deductResponse = await fetch('/api/deduct-balance', { method: 'POST' });
      const deductData = await deductResponse.json();
      
      if (!deductData.success) {
        setError(deductData.error);
        return;
      }
  
      setCredit(deductData.new_balance);
  
      // Get the full folder name for the selected folder
      const folder = folders.find(f => f.name === selectedFolders);
      const fullFolderName = folder ? folder.full_name : selectedFolders;
  
      const response = await fetch('/api/analyze', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          query, 
          model_choice: model, 
          selected_folders: [fullFolderName],  // Wrap in array since backend expects array
          language, 
          writing_style: writingStyle 
        })
      });
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
  
        for (const line of lines) {
          if (line.trim() !== '') {
            try {
              const outerData = JSON.parse(line);
              console.log('Outer data:', outerData);
              
              // If the status contains "Processed document", treat it as a progress update
              if (outerData.status.startsWith('Processed document')) {
                setStatus(outerData.status);
              } else {
                // Otherwise try to parse it as the final response JSON
                try {
                  const innerData = JSON.parse(outerData.status);
                  if (innerData.status === 'Complete') {
                    console.log('Setting docxUrl to:', innerData.docx_url);
                    setResponse(innerData.response);
                    setDocxUrl(innerData.docx_url);
                    setStatus('');
                  }
                } catch (innerError) {
                  // If it's not JSON, just use it as a status message
                  setStatus(outerData.status);
                }
              }
            } catch (e) {
              console.error('Error parsing outer JSON:', line, e);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error in handleAsk:', error);
      setStatus('Error: ' + error.message);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'GET',
        redirect: 'follow',
      });
    
      if (response.redirected) {
        window.location.href = response.url;
      } else {
        // If for some reason the server didn't redirect, we'll do it client-side
        localStorage.removeItem('isAuthenticated');
        history.push('/auth');
      }
    } catch (error) {
      console.error('Error during logout:', error);
      // Even if there's an error, we'll log out on the client side
      localStorage.removeItem('isAuthenticated');
      history.push('/auth');
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            <List>
              <ListItem button onClick={() => history.push('/credit-billing')}>
                <ListItemIcon><AccountCircle /></ListItemIcon>
                <ListItemText primary="Credit & Billing" />
              </ListItem>
              <ListItem button onClick={handleManageFoldersOpen}>
                <ListItemIcon><Folder /></ListItemIcon>
                <ListItemText primary="Manage Folders" />
              </ListItem>
              <ListItem button onClick={() => history.push('/about')}>
                <ListItemIcon><Info /></ListItemIcon>
                <ListItemText primary="About" />
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem button onClick={handleLogout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </div>
        </Drawer>
        <main className={classes.content}>
          <Box className={classes.logoContainer}>
            <img src="/images/logo_litwiz_dark.png" alt="LitWiz Logo" className={classes.logo} />
          </Box>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="body2" color="textSecondary">
              Please limit the number of files in the folder to a maximum of 20.<br />
              Only the first 20 files will be processed if more are uploaded.
            </Typography>
            <input
              accept="folder/*"
              style={{ display: 'none' }}
              id="folder-upload"
              type="file"
              webkitdirectory="true"
              onChange={handleFolderUpload}
            />
            <Box mb={2}>
              <label htmlFor="folder-upload">
              <Button
                  variant="contained"
                  color="primary"
                  startIcon={isUploading ? <CircularProgress size={24} /> : <CloudUpload />}
                  component="span"
                  className={classes.uploadButton}
                  disabled={isUploading || isProcessing}
              >
                  {isUploading ? 'Uploading...' : 'Upload Folder'}
              </Button>
              </label>
            </Box>

            {uploadStatus && (
              <Typography variant="body2" color="textSecondary">
                Status: {uploadStatus}
              </Typography>
            )}

            <Box mt={2}>
              <Typography className={classes.selectLabel}>Select a folder:</Typography>
              <Select
                value={selectedFolders}
                onChange={(e) => setSelectedFolders(e.target.value)}
                displayEmpty
                fullWidth
              >
                {folders.length === 0 ? (
                  <MenuItem disabled>No processed folders available</MenuItem>
                ) : (
                  folders.map((folder) => (
                    <MenuItem key={folder.name} value={folder.name}>
                      {folder.name} {folder.status !== 'Completed' && `(${folder.status})`}
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>

            <Box mt={2}>
              <Typography className={classes.selectLabel}>Choose a model:</Typography>
              <Select
                value={model}
                onChange={(e) => setModel(e.target.value)}
                fullWidth
              >
                <MenuItem value="Claude">Claude</MenuItem>
                <MenuItem value="ChatGPT">ChatGPT</MenuItem>
              </Select>
            </Box>

            <Box mt={2}>
              <Typography className={classes.selectLabel}>Output Language:</Typography>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                fullWidth
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="中文">中文</MenuItem>
              </Select>
            </Box>

            <Box mt={2}>
              <Typography className={classes.selectLabel}>Writing Style:</Typography>
              <Select
                value={language === "English" ? "Default" : writingStyle}
                onChange={(e) => setWritingStyle(e.target.value)}
                fullWidth
                disabled={language === "English"}
              >
                <MenuItem value="Default">Default (academic)</MenuItem>
                {language === "中文" && <MenuItem value="中文报告">中文报告</MenuItem>}
              </Select>
            </Box>

            <Box mt={2}>
              <Typography className={classes.selectLabel}>Enter the topic for this literature review:</Typography>
              <TextField
                multiline
                rows={2}
                variant="outlined"
                fullWidth
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="e.g., QE's impact on income inequality"
                InputProps={{
                  style: {
                    '&::placeholder': {
                      color: 'rgba(0, 0, 0, 0.38)',
                      fontStyle: 'italic'
                    }
                  }
                }}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              startIcon={<Send />}
              onClick={handleAsk}
              className={classes.askButton}
              disabled={credit < PRICE_PER_ASK}
            >
              Ask
            </Button>

            {/* Status and Download Area */}
            <Box mt={2}>
              {/* Show processing status */}
              {status && !status.includes("Complete") && (
                <Typography variant="body1">
                  {status}
                </Typography>
              )}
            
              {/* Show download button when URL is available */}
              {docxUrl && docxUrl.trim() !== '' && (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button 
                    href={docxUrl} 
                    download 
                    color="secondary"
                    variant="contained"
                    startIcon={<GetApp />}
                  >
                    Download Literature Review
                  </Button>
                </Box>
              )}
            </Box>
          </Paper>
        </main>
        <Snackbar open={uploadSuccess} autoHideDuration={6000} onClose={() => setUploadSuccess(false)}>
          <Alert onClose={() => setUploadSuccess(false)} severity="success">
              Folder successfully uploaded, preprocessed, and ready for analysis!
          </Alert>
        </Snackbar>
        <Snackbar open={!!uploadError} autoHideDuration={6000} onClose={() => setUploadError('')}>
          <Alert onClose={() => setUploadError('')} severity="error">
            {uploadError}
          </Alert>
        </Snackbar>
        <Snackbar open={paymentSuccess} autoHideDuration={6000} onClose={() => setPaymentSuccess(false)}>
          <Alert onClose={() => setPaymentSuccess(false)} severity="success">
            Payment successful! Your new balance is ${newBalance !== null ? newBalance.toFixed(2) : credit.toFixed(2)}
          </Alert>
        </Snackbar>
        <Dialog
          open={openManageFolders}
          onClose={handleManageFoldersClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Manage Folders</DialogTitle>
          <DialogContent>
            <ManageFolders onClose={handleManageFoldersClose} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleManageFoldersClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <footer className={classes.footer}>
          <Typography className={classes.footerText}>
            <span>Having problems or feedback? Email us at support@litwiz.ai</span>
          </Typography>
        </footer>
      </div>
    </ThemeProvider>
  );
}     

export default Dashboard;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core';
import Dashboard from './components/Dashboard';
import AuthPage from './components/AuthPage';
import OAuthCallback from './components/OAuthCallback';
import About from './components/About';
import ForgotPassword from './components/ForgotPassword';
import CreditBilling from './components/CreditBilling';


const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#1DF2A1',  // Our emerald color
      light: '#34f5ac', // Lighter shade for hover
      dark: '#18c986',  // Darker shade for active/pressed
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#000000',
      paper: '#141822',  // Dark blue-gray from your screenshot
    },
    text: {
      primary: '#ffffff',
      secondary: '#94a3b8',  // For secondary text
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#000000',  // Black text on emerald buttons
          '&:hover': {
            backgroundColor: '#34f5ac',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#141822',
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#000000',
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#000000',
  },
}));

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const response = await fetch('/api/check-auth');
      if (response.ok) {
        const data = await response.json();
        setIsAuthenticated(data.authenticated);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Switch>
          <Route 
            path="/auth" 
            render={() => isAuthenticated ? <Redirect to="/dashboard" /> : <AuthPage setIsAuthenticated={setIsAuthenticated} />} 
          />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route 
            path="/dashboard" 
            render={() => isAuthenticated ? 
              <Dashboard setIsAuthenticated={setIsAuthenticated} /> : 
              <Redirect to="/auth" />
            } 
          />
          <Route 
            path="/credit-billing" 
            render={() => isAuthenticated ? <CreditBilling /> : <Redirect to="/auth" />} 
          />          
          <Route 
            path="/about" 
            render={() => isAuthenticated ? <About /> : <Redirect to="/auth" />} 
          />
          <Route path="/oauth/callback/:provider" component={OAuthCallback} />
          <Redirect from="/" to={isAuthenticated ? "/dashboard" : "/auth"} />
        </Switch>
      </main>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

function OAuthCallback() {
  const { provider } = useParams();
  const history = useHistory();

  useEffect(() => {
    const handleOAuthCallback = async () => {
      try {
        const response = await fetch(`/api/login/${provider}/authorize${window.location.search}`);
        if (response.ok) {
          history.push('/dashboard');
        } else {
          throw new Error('OAuth authentication failed');
        }
      } catch (error) {
        console.error('Error during OAuth callback:', error);
        history.push('/auth');
      }
    };

    handleOAuthCallback();
  }, [provider, history]);

  return <div>Processing {provider} login...</div>;
}

export default OAuthCallback;
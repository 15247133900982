import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ loading: false, message: '', error: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, message: '', error: false });

    try {
      const response = await fetch('/api/request-password-reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setStatus({ 
          loading: false, 
          message: 'Password reset instructions sent to your email',
          error: false 
        });
        setEmail(''); // Clear email field
      } else if (response.status === 404) {
        // User not found case
        setStatus({ 
          loading: false, 
          message: data.message || `No account exists with email ${email}`,
          error: true 
        });
      } else if (response.status === 503) {
        // Database connection error
        setStatus({ 
          loading: false, 
          message: 'Service temporarily unavailable. Please try again later.',
          error: true 
        });
      } else {
        // Other errors
        setStatus({ 
          loading: false, 
          message: data.message || 'Failed to send reset email',
          error: true 
        });
      }
    } catch (error) {
      setStatus({ 
        loading: false, 
        message: 'Network error. Please check your connection and try again.',
        error: true 
      });
    }
  };
  
  return (
    <div className="p-4 w-full max-w-md">
      <h2 className="text-xl font-semibold mb-4">Reset Password</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          disabled={status.loading}
          className={`w-full p-2 rounded text-white ${
            status.loading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {status.loading ? (
            <span className="flex items-center justify-center">
              <CircularProgress size={20} className="mr-2" color="inherit" />
              Sending...
            </span>
          ) : (
            'Send Reset Link'
          )}
        </button>
      </form>
      
      {status.message && (
        <div className={`mt-4 p-2 rounded ${
          status.error ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
        }`}>
          {status.message}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  CircularProgress,
  Checkbox,
  Typography,
  Box
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

function ManageFolders({ onClose }) {
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFolders, setSelectedFolders] = useState([]);

  useEffect(() => {
    fetchFolders();
  }, []);

  const fetchFolders = async () => {
    try {
      const response = await fetch('/api/processed-folders');
      const data = await response.json();
      setFolders(data);
      setSelectedFolders([]);  // Reset selections when refreshing
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching folders:', error);
      setIsLoading(false);
    }
  };

  const handleDelete = async (folderName) => {
    try {
      const response = await fetch(`/api/delete-folder/${folderName}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchFolders();
      } else {
        console.error('Failed to delete folder');
      }
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };

  const handleBulkDelete = async () => {
    try {
      const deletionPromises = selectedFolders.map(folderName => 
        fetch(`/api/delete-folder/${folderName}`, {
          method: 'DELETE',
        })
      );
      
      await Promise.all(deletionPromises);
      fetchFolders();
    } catch (error) {
      console.error('Error in bulk deletion:', error);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedFolders(folders.map(folder => folder.name));
    } else {
      setSelectedFolders([]);
    }
  };

  const handleSelectFolder = (folderName) => {
    setSelectedFolders(prev => {
      if (prev.includes(folderName)) {
        return prev.filter(name => name !== folderName);
      } else {
        return [...prev, folderName];
      }
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedFolders.length > 0 && selectedFolders.length < folders.length}
                  checked={folders.length > 0 && selectedFolders.length === folders.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Folder Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {folders.map((folder) => (
              <TableRow key={folder.name}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedFolders.includes(folder.name)}
                    onChange={() => handleSelectFolder(folder.name)}
                  />
                </TableCell>
                <TableCell>{folder.name}</TableCell>
                <TableCell>{folder.status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Delete />}
                    onClick={() => handleDelete(folder.name)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedFolders.length > 0 && (
        <Box mt={2} mb={2}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Delete />}
            onClick={handleBulkDelete}
          >
            Delete Selected ({selectedFolders.length})
          </Button>
        </Box>
      )}
      

      <Box mt={3} p={2} bgcolor="#121212" borderRadius={1}>
        <Typography variant="body2" color="textSecondary">
          Uploaded folders will be saved on the servers for 3 days, allowing you to come back to the same folder and perform literature reviews 
          with different topics. After this period, folders will be automatically deleted to maintain system efficiency. 
          You can always re-upload any folder at any time - there is no charge for uploading folders and having your PDFs processed and stored.
        </Typography>
      </Box>
    </Box>
  );
}

export default ManageFolders;
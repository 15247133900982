import React from 'react';
import { 
  Typography, 
  Box, 
  makeStyles, 
  ThemeProvider, 
  createTheme, 
  CssBaseline 
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#1DF2A1',
    },
    background: {
      default: '#000000',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the logo and content box
    padding: theme.spacing(4),
    backgroundColor: '#000000',
    color: '#ffffff',
  },
  logo: {
    maxWidth: 320,
    width: '100%', 
    height: 'auto', 
    marginBottom: theme.spacing(9), // 3 lines of space after the logo
  },
  content: {
    maxWidth: 800,
    textAlign: 'left', // Align text to the left
  },
  subtitle: {
    color: '#05d665', // Green color as in the logo
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    textAlign: 'justify', // Justify the text
    marginBottom: theme.spacing(3), // Space between paragraphs
  },
  link: {
    color: '#05d665',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function About() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box className={classes.root}>
        <img src="/images/logo_litwiz_dark.png" alt="The LitWiz AI Logo" className={classes.logo} />
        <Box className={classes.content}>
          <Typography variant="h5" className={classes.subtitle}>
            Our Vision
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            Our vision is to harness the power of AI to revolutionize literature reviews. We aim to enable users to conduct thorough reviews on a topic quickly and efficiently, providing a solid foundation for their research.
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            Previously, synthesizing content from numerous papers was a time-consuming process. With The LitWiz AI, researchers can rapidly understand the current state of a particular field, including key facts, data, consensus views, and ongoing debates.
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            By using this tool, you'll gain a comprehensive overview of existing knowledge, enabling you to conduct further research more effectively and build upon the collective insights related to this topic.
          </Typography>
          
          <Typography variant="h5" className={classes.subtitle}>
            Pricing
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            Each query on The LitWiz AI costs $10. If your credit balance is lower than $10, you'll need to add credit to use this service.
          </Typography>
          
          <Typography variant="h5" className={classes.subtitle}>
            Contact
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            For additional information or queries, please email support@litwiz.ai.
          </Typography>          
        </Box>
        <Box mt={2}>
              <Link to="/dashboard" className={classes.link}>
                Return to the dashboard
              </Link>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default About;

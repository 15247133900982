import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../styles/sliding.css';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Divider, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  leftPanel: {
    width: '30%', // Increased from 25% to give more space for larger logo
    backgroundColor: '#000000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    position: 'relative', 
  },
  rightPanel: {
    width: '70%', // Adjusted to complement leftPanel
    backgroundImage: 'url("/images/background.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '100%',
    maxWidth: '400px', // Increased from 200px to make it double size
    marginBottom: theme.spacing(4),
  },
  intro: {
    color: '#fff',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  container: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '10px',
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    position: 'relative',
    overflow: 'hidden',
    width: '768px',
    maxWidth: '90%',
    minHeight: '480px',
  },
  socialContainer: {
    margin: '20px 0',
  },
  socialButton: {
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px',
    height: '40px',
    width: '40px',
    backgroundColor: '#fff',
  },
  googleIcon: {
    width: '20px',
    height: '20px',
  },
  orDivider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
  },
  line: {
    flexGrow: 1,
    height: '1px',
    backgroundColor: '#e0e0e0',
  },
  orText: {
    margin: '0 10px',
    color: '#757575',
    fontSize: '14px',
  },
  signUpForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  signUpButton: {
    marginTop: theme.spacing(2),
  },
  footer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    color: '#fff',
    fontSize: '0.875rem',
    opacity: 0.8,
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  forgotPassword: {
    color: '#666',
    fontSize: '0.9rem',
    textDecoration: 'none',
    marginTop: theme.spacing(2),
    display: 'block',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  formFooter: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  }
}));

function AuthPage({ setIsAuthenticated }) {
  const classes = useStyles();
  const [isSignUp, setIsSignUp] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const oauthProvider = urlParams.get('oauth_provider');
    const code = urlParams.get('code');
    if (oauthProvider && code) {
      handleOAuthCallback(oauthProvider, code);
    }
  }, []);

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
    setName('');
    setEmail('');
    setPassword('');
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const endpoint = `/api/${isSignUp ? 'register' : 'login'}`;
      const payload = isSignUp 
        ? { name, email, password }
        : { email, password };

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        setIsAuthenticated(true);
        history.push(data.redirect_url || '/dashboard');
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialSignIn = (provider) => {
    setIsLoading(true);
    setError(null);
    window.location.href = `/api/login/${provider}`;
  };

  const handleOAuthCallback = async (provider, code) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`/api/login/${provider}/callback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code }),
      });
      if (response.ok) {
        history.push('/dashboard');
      } else {
        const data = await response.json();
        setError(data.message);
      }
    } catch (err) {
      setError('An error occurred during social sign-in. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Box className={classes.leftPanel}>
        <div className={classes.mainContent}>
          <img src="/images/logo_litwiz_dark.png" alt="LitWiz Logo" className={classes.logo} />
          <Typography variant="h4" className={classes.intro}>
            Welcome to LitWiz
          </Typography>
          <Typography variant="body1" className={classes.intro} style={{ 
            maxWidth: '80%', 
            lineHeight: 1.8,
            margin: '0 auto',
            fontSize: '1.1rem',
            fontWeight: 300,
            letterSpacing: '0.015em'
          }}>
            Have a folder of research papers?{' '}
            <br />
            Turn it into an insightful literature review in one click.
          </Typography>
          <Typography 
            variant="body2" 
            className={classes.intro}
            style={{ 
              backgroundColor: 'rgba(29, 242, 161, 0.1)', 
              color: '#1DF2A1',
              padding: '4px 8px',
              borderRadius: '4px',
              marginTop: '8px'
            }}
          >
            Beta Version
          </Typography>
        </div>
        <Typography className={classes.footer}>
          &copy; 2024 LitWiz. All Rights Reserved.
        </Typography>
      </Box>
      <Box className={classes.rightPanel}>
        <div className={`container ${isSignUp ? 'right-panel-active' : ''}`}>
          <div className="form-container sign-up-container">
            <form onSubmit={handleSubmit} className={classes.signUpForm}>
              <h1>Create Account</h1>
              <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
              <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
              <button type="submit" disabled={isLoading} className={classes.signUpButton}>Sign Up</button>
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form onSubmit={handleSubmit}>
              <h1>Sign In</h1>
              <button className="gsi-material-button" onClick={() => handleSocialSignIn('google')} type="button">
                <div className="gsi-material-button-state"></div>
                <div className="gsi-material-button-content-wrapper">
                  <div className="gsi-material-button-icon">
                    <svg width="18" height="18" viewBox="0 0 48 48" style={{ display: 'block' }}>
                      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
                      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
                      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
                      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
                      <path fill="none" d="M0 0h48v48H0z" />
                    </svg>
                  </div>
                  <span className="gsi-material-button-contents">Sign in with Google</span>
                  <span style={{ display: 'none' }}>Sign in with Google</span>
                </div>
              </button>
              <div className={classes.orDivider}>
                <div className={classes.line}></div>
                <span className={classes.orText}>or</span>
                <div className={classes.line}></div>
              </div>
              <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
              <button type="submit" disabled={isLoading}>Sign In</button>
              <div className={classes.formFooter}>
                <Link to="/forgot-password" className={classes.forgotPassword}>
                  Forgot Password?
                </Link>
              </div>
              {error && <div className="error-message">{error}</div>}
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back, Researcher!</h1>
                <p>Sign in to continue your literature review with LitWiz</p>
                <button className="ghost" onClick={toggleForm}>Sign In</button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Researcher!</h1>
                <p>Enter your details and start your journey with LitWiz</p>
                <button className="ghost" onClick={toggleForm}>Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default AuthPage;
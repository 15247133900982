import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowLeft, Plus } from 'lucide-react';
import { Box, Button, TextField, Typography, Container, Paper, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  returnButton: {
    marginBottom: theme.spacing(4),
  },
  balanceCard: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
  },
  balanceLabel: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    fontSize: '0.875rem',
  },
  balanceAmount: {
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    fontWeight: 700,
    lineHeight: 1.2,
  },
  amountButton: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(6),
    minHeight: '160px', // Taller to match Tailwind version
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    '& .amount': {
      fontSize: '1.8rem', // Much larger, like in Tailwind version
      fontWeight: 700,
      color: theme.palette.text.primary,
    },
  },
  gridContainer: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
  },
  transactionItem: {
    padding: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  errorPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
}));

const PRESET_AMOUNTS = [20, 50, 100, 200];

export default function EnhancedCreditBilling() {
  const classes = useStyles();
  const history = useHistory();
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [customAmount, setCustomAmount] = useState('');

  useEffect(() => {
    fetchBalance();
    fetchTransactions();
  }, []);

  const fetchBalance = async () => {
    try {
      const response = await fetch('/api/user-info');
      if (!response.ok) throw new Error('Failed to fetch balance');
      const data = await response.json();
      setBalance(data.credit);
    } catch (error) {
      setError('Failed to fetch balance');
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await fetch('/api/transactions');
      if (!response.ok) throw new Error('Failed to fetch transactions');
      const data = await response.json();
      setTransactions(data.transactions);
    } catch (error) {
      setError('Failed to load transaction history');
    }
  };

  const handlePayment = async (amount) => {
    if (amount < 10 || amount > 1000) {
      setError('Amount must be between $10 and $1000');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: parseFloat(amount) }),
      });

      if (!response.ok) throw new Error('Failed to create checkout session');

      const { id: sessionId } = await response.json();
      
      const stripePromise = await fetch('/api/stripe-config')
        .then(r => r.json())
        .then(({ publicKey }) => loadStripe(publicKey));
      
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      setError(error.message || 'Failed to process payment');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || 
        (/^\d+$/.test(value) && 
        parseInt(value) >= 10 && 
        parseInt(value) <= 1000 && 
        parseInt(value) % 10 === 0)) {
      setCustomAmount(value);
    }
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      {/* Return Button */}
      <Button
        onClick={() => history.push('/dashboard')}
        variant="contained"
        color="primary"
        startIcon={<ArrowLeft />}
        className={classes.returnButton}
      >
        Return to Dashboard
      </Button>

      {/* Balance Card */}
      <Paper className={classes.balanceCard}>
        <Typography className={classes.balanceLabel}>
          Current Balance
        </Typography>
        <Typography className={classes.balanceAmount}>
          ${balance.toFixed(2)}
        </Typography>
      </Paper>

      {/* Amount Options */}
      <Grid container spacing={3} className={classes.gridContainer}>
        {PRESET_AMOUNTS.map((amount) => (
          <Grid item xs={12} sm={6} md={3} key={amount}>
            <Button
              fullWidth
              className={classes.amountButton}
              onClick={() => handlePayment(amount)}
            >
              <span className="amount">${amount}</span>
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Custom Amount */}
      <Paper className={`${classes.paper} ${classes.customAmountBox}`} style={{ marginTop: '32px' }}>
        <Typography variant="subtitle1" color="textSecondary">
          Or enter a custom amount
        </Typography>
        <Box display="flex" gap={2} alignItems="center" style={{ marginTop: '16px' }}>
          <TextField
            label="Enter Amount ($10-$1000)"
            type="number"
            value={customAmount}
            onChange={(e) => {
              const value = e.target.value;
              // Allow typing but only set if it's valid
              if (value === '' || (/^\d+$/.test(value) && parseInt(value) <= 1000)) {
                setCustomAmount(value);
              }
            }}
            onBlur={() => {
              // Validate on blur: ensure it's a multiple of 10 and within range
              if (customAmount && (parseInt(customAmount) < 10 || parseInt(customAmount) > 1000 || parseInt(customAmount) % 10 !== 0)) {
                setCustomAmount('');
              }
            }}
            fullWidth
            variant="outlined"
            inputProps={{
              min: 10,
              max: 1000,
              step: 10,
            }}
            error={
              customAmount &&
              (!(/^\d+$/.test(customAmount) && parseInt(customAmount) % 10 === 0 && parseInt(customAmount) >= 10 && parseInt(customAmount) <= 1000))
            }
            helperText={
              customAmount &&
              (!(/^\d+$/.test(customAmount) && parseInt(customAmount) % 10 === 0 && parseInt(customAmount) >= 10 && parseInt(customAmount) <= 1000))
                ? 'Enter a multiple of 10 between $10 and $1000'
                : ''
            }
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={isLoading ? <CircularProgress size={20} /> : <Plus />}
            onClick={() => handlePayment(customAmount)}
            disabled={
              !customAmount ||
              parseInt(customAmount) < 10 ||
              parseInt(customAmount) > 1000 ||
              parseInt(customAmount) % 10 !== 0 ||
              isLoading
            }
            style={{
              whiteSpace: 'nowrap',
              fontSize: '1rem',
              fontWeight: 'bold',
              padding: '12px 24px',
            }}
          >
            Add Credit
          </Button>
        </Box>
      </Paper>

      {/* Transaction History */}
      <Paper className={classes.paper} style={{ marginTop: 24 }}>
        <Typography variant="h6" gutterBottom>
          Transaction History
        </Typography>
        {transactions.length > 0 ? (
          <div>
            {transactions.map((transaction, index) => (
              <Box 
                key={index}
                className={classes.transactionItem}
                display="flex" 
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="body1">{transaction.type}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {transaction.date}
                  </Typography>
                </Box>
                <Typography 
                  variant="body1" 
                  color={transaction.amount > 0 ? 'primary' : 'textSecondary'}
                >
                  {transaction.amount > 0 ? '+' : ''}{transaction.amount}
                </Typography>
              </Box>
            ))}
          </div>
        ) : (
          <Box py={4} textAlign="center">
            <Typography color="textSecondary">
              No recent transactions
            </Typography>
          </Box>
        )}
      </Paper>

      {/* Error Display */}
      {error && (
        <Paper className={classes.errorPaper}>
          <Typography>{error}</Typography>
        </Paper>
      )}
    </Container>
  );
}